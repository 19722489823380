<template>
  <div>
    <div class="title">新建任务</div>
    <div class="step">
      <div class="left">
        <a-steps :current="step" size="default">
          <a-step title="创建任务" />
          <a-step title="选择服务人员" />
          <a-step title="完成" />
        </a-steps>
      </div>
      <!-- 第一步 -->
      <div v-if="step===0" class="right">
        <a-form style="margin-top:50px" :form="form"  @submit="handleSubmit">
          <a-form-item label="任务名称">
            <a-input
              placeholder="请输入任务名称(限30个字)"
              style="width:418px"
              :maxLength="30"
              v-decorator="['title', { rules: [{ required: true, message: '请输入任务名称!' }] }]"
            />
          </a-form-item>
          <a-form-item label="任务类别">
            <a-cascader
              v-decorator="[
                'taskTypes',
                { rules: [{ required: true, message: '请选择任务类别!' }] },
              ]"
              style="width:418px"
              :options="options"
              placeholder="请选择任务类别"
              @change="onChange"
            />
          </a-form-item>
          <a-form-item style="margin-left:10px" label="发票类目" v-if="ticketContentList.length>0">
            <div v-if="ticketContentList.length>0" style="width:428px;line-height:24px;height:39px;display:flex;align-items:center;padding-left:10px;text-align:left">
              <span v-for="(item,index) in ticketContentList" :key="index">{{item.invoiceName}} <span v-show="index < ticketContentList.length-1">、</span> </span>
            </div>
          </a-form-item>
          <a-form-item label="报名时间">
            <a-range-picker @change="bmTime" v-decorator="['bmsj', {rules: [{ type: 'array', required: true, message: '请选择报名时间!' }]}]" />
          </a-form-item>
          <a-form-item label="任务时间">
            <a-range-picker @change="rwTime" v-decorator="['rwsj', {rules: [{ type: 'array', required: true, message: '请选择任务时间!' }]}]" />
          </a-form-item>
          <a-form-item label="预计人数">
            <a-input
              style="width:418px"
              placeholder="请输入预计人数"
              suffix="人"
              min="1"
              type="number"
              v-decorator="['needNum', { rules: [{ required: true, message: '请输入预计人数!' }] }]"
            />
          </a-form-item>
          <a-form-item label="预算金额">
            <a-input
              suffix="元"
              style="width:418px"
              :maxLength="11"
              placeholder="请输入金额"
              v-decorator="['budgetAmount', { rules: [{ required: true, message: '请输入预算金额!' },{validator: validateInputCode}] }]"
            />
          </a-form-item>
          <a-form-item label="任务描述">
            <a-textarea
              placeholder="请输入任务描述"
              style="width:418px"
              :autoSize="{ minRows: 3, maxRows: 5 }"
              v-decorator="['content', { rules: [{ required: true, message: '请输入任务描述!' }] }]"
            />
          </a-form-item>
          <a-form-item label="结算类型">
            <a-radio-group v-decorator="['payMode', {rules: [{ required: true, message: '请选择结算类型', whitespace: true}]}]" style="width: 418px">
              <a-radio value="10">
                单次结算
              </a-radio>
              <a-radio value="20">
                多次结算
              </a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 12, offset: 2 }">
            <a-button type="primary" html-type="submit" style="margin-left:40px">
              下一步
            </a-button>
            <a-button @click="cancelAdd" :style="{ marginLeft: '8px' }">
              取消
            </a-button>
          </a-form-item>
        </a-form>
      </div>
      <!-- 第二步 -->
      <div v-if="step===1" class="step-02">
        <div class="header">
          <span>推荐优质人才</span>
          <div v-if="submitDetail.payMode==='10'">
            <a-button type="link" :loading="changeLoading" @click="changeOne">换一批</a-button>
            <a-button type="primary" @click="inviteUser" style="margin-left:10px">邀请人员</a-button>
            <a-button type="primary" @click="inviteUsers" style="margin-left:10px">批量邀请</a-button>
          </div>
          <div v-else>
            <a-button type="link" :loading="changeLoading" @click="changeOne">换一批</a-button>
            <a-button type="primary" @click="inviteUserMany" style="margin-left:10px">邀请人员</a-button>
          </div>
        </div>
        <ul class="content">
          <li v-for="(item,index) in inviteLists" :key="index">
            <div class="construction">
              <div class="c-left" v-if="!item.avatarUrl">
                {{item.userName.slice(0,1)}}
              </div>
              <div class="c-left" style="background:#FFFFFF" v-else>
                <img style="width:64px;height:64px;border-radius:50%" :src="item.avatarUrl" alt="">
              </div>
              <div class="c-right">
                <div class="cr-title">
                  <div>
                    <span class="name">{{item.userName.replace((/(.{1}).{1,}/),"$1**")}}</span>
                    <span class="phone">{{item.phoneNo.replace((/(\d{3})\d{4}(\d{4})/),"$1****$2")}}</span>
                  </div>
                  <div class="hzcs">合作次数 {{item.times}}</div>
                </div>
                <div class="desc" v-if="item.intr.itemText" :title="item.intr.itemText">{{item.intr.itemText}}</div>
                <!-- <div class="desc" v-else>这人很懒，什么都没留下</div> -->
                <div>
                  <a-button type="primary" style="background:#70B2EF;border:0" v-if="item.hasInvite===false&&submitDetail.payMode==='10'" @click="qryg(item)">邀请加入</a-button>
                  <a-button type="primary" style="background:#70B2EF;border:0" v-if="item.hasInvite===false&&submitDetail.payMode==='20'" @click="qrygMany(item)">邀请加入</a-button>
                  <a-button type="link" style="color:rgba(0,0,0,0.45)" v-if="item.hasInvite===true&&submitDetail.payMode==='10'">已发送</a-button>
                  <a-button type="link" style="color:rgba(0,0,0,0.45)" v-if="item.hasInvite===true&&submitDetail.payMode==='20'">已发送</a-button>
                </div>
              </div>
            </div>
            <div class="footer">
              <div>{{item.workYears}}年工作经验</div>
              <div>
                <span v-if="item.tags.itemText">{{item.tags.itemText}}</span>
              </div>
            </div>
          </li>
        </ul>
        <div class="stept-opt">
          <a-button type="primary" @click="nextTwo">下一步</a-button>
          <!-- <a-button style="margin-left:20px" @click="nextTwo">跳过</a-button> -->
        </div>
        <!-- 发送邀请单次 -->
        <a-modal
          title="发送邀请"
          width="720px"
          :visible="visibleYq"
          :footer="null"
          @cancel="handleCancelYq"
        >
          <div class="fsyq-head">
            <div>
              <div class="fsyq-title">请输入需要被邀请人的手机号码</div>
              <div>
                <a-input-search v-model="inviteUserPhone" style="width:360px;height:32px" placeholder="请输入手机号码" @search="onSearchInvite">
                  <a-button slot="enterButton">
                    搜索
                  </a-button>
                </a-input-search>
              </div>
            </div>
            <div class="user-info" v-if="hasInvitor">
              <div class="img" v-if="currentItem">{{currentItem.userName.slice(0,1)}}</div>
              <div v-if="currentItem">{{currentItem.userName}}</div>
            </div>
          </div>
          <div class="common-content" v-if="hasInvitor">
            <div class="cc-title">服务价格信息</div>
            <div class="cc-desc">请确认用工人员的服务价格</div>
            <div class="cc-content">
              <span>任务金额</span>
              <a-input :maxLength="11" v-model="rwje" min="0" @change="onChangeRwje" style="width:160px;margin-left:16px" type="number" suffix="元"></a-input>
              <span style="margin-left:20px;">费用：{{fy}}元</span>
              <span style="margin-left:20px;">总金额：<span style="color:#FF4D4F">{{allFys}}</span>元</span>
            </div>
            <div class="cc-content">
              <span>预付金额</span>
              <a-input :maxLength="11" :readOnly="yfje===0?false:true" @blur="tofixed" min="0" v-model="inputYfje" style="width:160px;margin:0 0 0 16px" type="number" suffix="元"></a-input>
              <a-radio-group style="margin-left:20px" name="radioGroup" v-model="yfje"  @change="onChangeYfje">
                <a-radio :value="0.1">
                  10%
                </a-radio>
                <a-radio :value="0.2">
                  20%
                </a-radio>
                <a-radio :value="0.3">
                  30%
                </a-radio>
                <a-radio :value="0.4">
                  40%
                </a-radio>
                <a-radio :value="0">
                  自定义
                </a-radio>
              </a-radio-group>
            </div>
            <div class="cc-footer">
              <div class="ccf-left">
                <div>预付款：<span>{{inputYfje}}<span style="font-size:20px">元</span></span></div>
                <div>双方达成合作后付款</div>
              </div>
              <div class="ccf-right">
                <div>尾款：<span>{{wk}}<span style="font-size:20px">元</span></span></div>
                <div>任务完成后付款</div>
              </div>
            </div>
          </div>
          <div class="no-invite" v-if="!hasInvitor&&stepTwoShowContentYq">
            <div>该用户暂未注册分时领英小程序平台</div>
            <div style="margin-top:20px"><a-button type="link" @click="sendDx">发送短信邀请</a-button></div>
          </div>
          <div class="common-opt">
            <a-button @click="handleCancelYq">取消</a-button>
            <a-button type="primary" style="margin-left:10px" :loading='confirmLoadingYq' :disabled="!hasInvitor" @click="handleOkYq">确认发送</a-button>
          </div>
        </a-modal>
        <!-- 发送邀请多次 -->
        <a-modal
          title="发送邀请"
          width="720px"
          :visible="visibleYqMany"
          :footer="null"
          @cancel="handleCancelYqMany"
        >
          <div class="fsyq-head">
            <div>
              <div class="fsyq-title">请输入需要被邀请人的手机号码</div>
              <div>
                <a-input-search v-model="inviteUserPhone" style="width:360px;height:32px" placeholder="请输入手机号码" @search="onSearchInvite">
                  <a-button slot="enterButton">
                    搜索
                  </a-button>
                </a-input-search>
              </div>
            </div>
            <div class="user-info" v-if="hasInvitor">
              <div class="img" v-if="currentItem">{{currentItem.userName.slice(0,1)}}</div>
              <div v-if="currentItem">{{currentItem.userName}}</div>
            </div>
          </div>
          <div class="no-invite" v-if="!hasInvitor&&stepTwoShowContentYq">
            <div>该用户暂未注册分时领英小程序平台</div>
            <div style="margin-top:20px"><a-button type="link" @click="sendDx">发送短信邀请</a-button></div>
          </div>
          <div class="common-opt">
            <a-button @click="handleCancelYqMany">取消</a-button>
            <a-button type="primary" style="margin-left:10px" :loading='confirmLoadingYq' :disabled="!hasInvitor" @click="handleOkYq">确认发送</a-button>
          </div>
        </a-modal>
        <!-- 确认用工 -->
        <a-modal
          title="确认用工"
          width="720px"
          :visible="visibleYg"
          :footer="null"
          @cancel="handleCancelYg"
        >
          <div class="fsyq-head">
            <div class="user-info">
              <div class="img" v-if="currentItem">{{currentItem.userName.slice(0,1)}}</div>
              <div v-if="currentItem">{{currentItem.userName.replace((/(.{1}).{1,}/),"$1**")}}</div>
            </div>
          </div>
          <div class="common-content">
            <div class="cc-title">服务价格信息</div>
            <div class="cc-desc">请确认用工人员的服务价格</div>
            <div class="cc-content">
              <span>任务金额</span>
              <a-input v-model="rwje" :maxLength="11" min="0" @change="onChangeRwje" style="width:160px;margin-left:16px" type="number" suffix="元"></a-input>
              <span style="margin-left:20px;">费用：{{fy}}元</span>
              <span style="margin-left:20px;">总金额：<span style="color:#FF4D4F">{{allFys}}</span>元</span>
            </div>
            <div class="cc-content">
              <span>预付金额</span>
              <a-input :maxLength="11" :readOnly="yfje==='0'?true:false" min="0" @blur="tofixed" v-model="inputYfje" style="width:160px;margin:0 0 0 16px" type="number" suffix="元"></a-input>
              <a-radio-group style="margin-left:20px" name="radioGroup" v-model="yfje"  @change="onChangeYfje">
                <a-radio :value="0.1">
                  10%
                </a-radio>
                <a-radio :value="0.2">
                  20%
                </a-radio>
                <a-radio :value="0.3">
                  30%
                </a-radio>
                <a-radio :value="0.4">
                  40%
                </a-radio>
                <a-radio :value="0">
                  自定义
                </a-radio>
              </a-radio-group>
            </div>
            <div class="cc-footer">
              <div class="ccf-left">
                <div>预付款：<span>{{inputYfje}}<span style="font-size:20px">元</span> </span></div>
                <div>双方达成合作后付款</div>
              </div>
              <div class="ccf-right">
                <div>尾款：<span>{{wk}}<span style="font-size:20px">元</span></span></div>
                <div>任务完成后付款</div>
              </div>
            </div>
          </div>
          <div class="common-opt">
            <a-button @click="handleCancelYg">取消</a-button>
            <a-button type="primary" style="margin-left:10px" :loading='confirmLoadingYg' @click="handleOkYg">确认用工</a-button>
          </div>
        </a-modal>
        <!-- 确认用工多次 -->
        <a-modal
          title="确认用工"
          width="720px"
          :visible="visibleYgMany"
          :footer="null"
          @cancel="handleCancelYgMany"
        >
          <div class="fsyq-head">
            <div class="user-info">
              <div class="img" v-if="currentItem">{{currentItem.userName.slice(0,1)}}</div>
              <div v-if="currentItem">{{currentItem.userName.replace((/(.{1}).{1,}/),"$1**")}}</div>
            </div>
          </div>
          <div class="common-opt">
            <a-button @click="handleCancelYgMany">取消</a-button>
            <a-button type="primary" style="margin-left:10px" :loading='confirmLoadingYg' @click="handleOkYg">确认用工</a-button>
          </div>
        </a-modal>
        <!-- 批量邀请 -->
        <a-modal
          title="发送邀请"
          width="850px"
          :visible="visiblePl"
          :footer="null"
          @cancel="handleCancelPl"
        >
          <div style="position:relative;align-items:center">
            <a-upload
              name="file"
              :multiple="false"
              accept=".xlsx"
              :action="url"
              :headers="headers"
              :fileList="uploadInfo"
              :data='importData'
              @change="handleChange"
            >
              <a-button> 导入数据 </a-button>
            </a-upload>
            <a href="https://tax-trade.oss-cn-chengdu.aliyuncs.com/template/worker_template.xlsx" target="blink" class="xzmb">下载模板</a>
          </div>
          <div class="pl-table">
            <s-table
              ref="raskTable"
              :columns="columnsPl"
              :data="raskData"
              :rowKey="(record) => record.id"
              :scroll="{ y: 300 }"
            >
              <span slot="action" slot-scope="text, record">
                <a v-if="record.loginStatus===0" @click="sendMsg(record)">发送短信</a>
              </span>
            </s-table>
          </div>
          <div class="pl-total">
            <span class="total-ze">任务总额：<span class="total-zee">{{(Math.floor(tableInfo.taskAmount * 100) / 100).toFixed(2)}}</span><span class="total-zeee">元</span></span>
            <span class="total-ze">预付金额：<span class="total-zeee">{{(Math.floor(tableInfo.advanceAmount * 100) / 100).toFixed(2)}}元</span></span>
            <span class="total-ze">尾款金额：<span class="total-zeee">{{(Math.floor(tableInfo.balanceAmount * 100) / 100).toFixed(2)}}元</span></span>
            <span class="total-ze">费用：<span class="total-zeee">{{(Math.floor(tableInfo.feeAmount * 100) / 100).toFixed(2)}}元</span></span>
            <span class="total-ze">付款总额：<span class="total-zee">{{(Math.floor(tableInfo.payAmount * 100) / 100).toFixed(2)}}</span><span class="total-zeee">元</span></span>
            <span class="total-ze">总计人数：<span class="total-zeee">{{tableInfo.countNum}}人</span></span>
          </div>
          <div class="common-opt">
            <a-button @click="handleCancelPl">取消</a-button>
            <a-button type="primary" style="margin-left:10px" :disabled="!allowSubmit" :loading='confirmLoadingPl' @click="handleOkPl">发送邀请</a-button>
          </div>
        </a-modal>
      </div>
      <!-- 第三步 -->
      <div v-if="step===2" class="step-03">
        <div><a-icon type="check-circle" style="fontSize:70px;color:#73D13D" theme="filled" /></div>
        <div class="step3-head">任务创建成功</div>
        <div class="step3-desc">自由职业者可通过关注【智赋云结算】公众号,进入分时领英工作者端报名任务</div>
        <div>
          <a-button @click="contiuneCreate">继续创建任务</a-button>
          <a-button type="primary" style="margin-left:20px" @click="toDetail">查看任务详情</a-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  raskListAdd,
  getRaskType,
  getRandomRecommendUser,
  checkRepetition,
  invitationRecommendUser,
  getUserByPhone,
  sendInvitationSms,
  getUserData,
  getInvoiceByIndustryId,
  batchInvitationRecommendUser,
  getFeePoint,
  queryImportWorkerList,
  submitApply,
  importWorkerStatistics
} from '@/api/api'
import moment from 'moment'
import Vue from 'vue'
import { ACCESS_TOKEN } from "@/store/mutation-types";
import STable from '@/components/table/'
export default {
  components: {
    STable
  },
  data() {
    return {
      // 步骤条
      step: 0,
      // 第一步
      formLayout: 'horizontal',
      form: this.$form.createForm(this, { name: 'coordinated' }),
      options: [],
      params: {
        taskType: '',
        enrollBeginDate: '',
        enrollEndDate: '',
        workBeginDate: '',
        workEndDate: ''
      },
      taskId: '',
      // 第二步
      inviteLists: [],
      visibleYq: false,
      confirmLoadingYq: false,
      yfje: 0,
      rwje: 0,
      inputYfje: 0,
      hasInvitor: false, // 是否查询到邀请用户
      visibleYg: false, //确认用工
      visibleYgMany: false, //确认用工多次
      confirmLoadingYg: false,
      changeLoading: false,
      currentItem: null,
      searchInviteUser: '',
      inviteUserPhone: '',
      stepTwoShowContentYq: false,
      tagsLists: [],
      intruductionLists: [],
      ticketContentList: [],
      userInfo: '',
      visiblePl: false,
      confirmLoadingPl: false,
      headers: {'X-Access-Token': Vue.ls.get(ACCESS_TOKEN)},
      columnsPl: [
        {
          title: '姓名',
          dataIndex: 'userName',
          width: '100px'
        },
        {
          title: '电话',
          dataIndex: 'phoneNo',
          width: '150px'
        },
        {
          title: '预付金额',
          dataIndex: 'advanceAmount',
          customRender: text => text === null?'--':(Math.floor(text * 100) / 100).toFixed(2),
          width: '100px'
        },
        {
          title: '尾款金额',
          dataIndex: 'balanceAmount',
          customRender: text => text === null?'--':(Math.floor(text * 100) / 100).toFixed(2),
          width: '100px'
        },
        {
          title: '注册状态',
          dataIndex: 'loginStatus',
          customRender: text => text === 1?'已注册':'未注册',
          width: '100px'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '100px',
          scopedSlots: { customRender: 'action' },
        },
      ],
      dataPl:[],
      importData: {
        taskId: this.taskId
      },
      url: window._CONFIG['domianURL'] + '/slash-youth/client/b/task/importWorker',
      pagenation: {
        pageSize: 30000,
        hideOnSinglePage: true
      },
      uploadInfo: [],
      taxPoint: '',
      batchId: '',
      allowSubmit: false,
      tableInfo: {
        taskAmount: 0.00,
        advanceAmount:0.00,
        balanceAmount:0.00,
        feeAmount:0.00,
        payAmount:0.00,
        countNum:0.00,
      },
      submitDetail: '',
      visibleYqMany: false
    };
  },
  created () {
    this.getTags()
    this.getIntruduction()
    this.form.resetFields()
    this.getRaskType()
    this.params = {
      taskType: '',
      enrollBeginDate: '',
      enrollEndDate: '',
      workBeginDate: '',
      workEndDate: ''
    }
    setTimeout(()=>{
      this.form.setFieldsValue({
        payMode: '10'
      })
    },50)
    // this.getRandomRecommendUser()
    this.getPoint()
  },
  computed: {
    // 费用
    fy () {
      if (this.rwje) {
        return (this.rwje*this.taxPoint).toFixed(2)
      } else {
        return 0.00
      }
    },
    // 总金额
    allFys () {
      if (this.rwje) {
        return (this.rwje*this.taxPoint+Number(this.rwje)).toFixed(2)
      } else {
        return 0.00
      }
    },
    // 尾款
    wk () {
      if (this.inputYfje&&this.rwje) {
        return (this.rwje-this.inputYfje).toFixed(2)
      } else {
        return 0.00
      }
    },
  },
  methods: {
    // 查询批量上传工作者列表
    raskData(parameter) {
      return queryImportWorkerList(Object.assign(parameter,{batchId:this.batchId})).then(res => {
        this.dataPl = res.result.data
        this.allowSubmit = res.result.data.length===0?false:true
        return res.result
      })
    },
    // 获取费率
    getPoint () {
      getFeePoint().then(res =>  {
        this.taxPoint = res.result
      })
    },
    // 第一步
    validateInputCode(rule,value,callback){
      let s = Number(value)
      if(!isNaN(s)){
        callback();
      }else{
        callback("请输入大于零的数!");
      }
    },
    // 获取任务类型
    getRaskType () {
      getRaskType().then(res => {
        if (res.success) {
          this.options = res.result
          this.options.forEach(item=>{
            item.label = item.industryName
            item.value = item.id
            if (item.children.length>0) {
              item.children.forEach(list => {
                list.label = list.industryName
                list.value = list.id
                list.children = ''
              })
            }
          })
        }
      })
    },
    onChange (value) {
      this.params.taskType = value[1]
      getInvoiceByIndustryId(value[value.length-1]).then(res => {
        if (res.success) {
          this.ticketContentList = res.result
        }
      })
    },
    // 报名时间
    bmTime(date, dateString) {
      this.params.enrollBeginDate = dateString[0]
      this.params.enrollEndDate = dateString[1]
    },
    // 任务时间
    rwTime (date, dateString) {
      this.params.workBeginDate = dateString[0]
      this.params.workEndDate = dateString[1]
    },
    // 提交
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // console.log('Received values of form: ', values);
          let params = Object.assign(values,this.params)
          // if (moment(params.workBeginDate).isBefore(params.enrollEndDate)) {
          //   this.$message.error('任务开始时间需大于报名结束时间')
          //   return
          // }
          params.budgetAmount = Number(params.budgetAmount).toFixed(2)
          raskListAdd(params).then(res => {
            if (res.success) {
              this.taskId = res.result.taskId
              this.importData.taskId = res.result.taskId
              this.$message.success(res.message)
              this.form.resetFields()
              this.getRandomRecommendUser()
              this.submitDetail = params
              this.step = 1
            } else {
              this.$message.error(res.message)
            }
          })
        }
      });
    },
    // 下拉选择
    handleSelectChange(value) {
      console.log(value);
      this.form.setFieldsValue({
        note: `Hi, ${value === 'male' ? 'man' : 'lady'}!`,
      });
    },
    // 取消
    cancelAdd () {
      window.localStorage.setItem('submenu','raskManage')
      this.$router.push('/raskManage')
    },
    // 第二步
    nextTwo () {
      this.step = 2
    },
    tofixed () {
      this.inputYfje = Number(this.inputYfje).toFixed(2)
    },
    // 获取行业标签
    getTags () {
      getUserData({code:'person_tags'}).then(res => {
        if (res.success) {
          this.tagsLists = res.result
        }
      })
    },
    // 获取行业标签
    getIntruduction () {
      getUserData({code:'person_intruduction'}).then(res => {
        if (res.success) {
          this.intruductionLists = res.result
        }
      })
    },
    // 获取优质人才
    getRandomRecommendUser () {
      getRandomRecommendUser().then(res => {
        if(res.success) {
          this.inviteLists = res.result
          this.inviteLists.forEach(item => {
            let times = Math.ceil(Math.random() * 10)
            let workYears = Math.ceil(Math.random() * 10)
            let tags = Math.floor(Math.random() * this.tagsLists.length)
            let intr = Math.floor(Math.random() * this.intruductionLists.length)
            item.hasInvite = false
            item.times = times
            item.workYears = workYears
            item.tags = this.tagsLists[tags]
            item.intr = this.intruductionLists[intr]
          })
        }
      })
    },
    // 换一批
    changeOne () {
      this.changeLoading = true
      this.getRandomRecommendUser()
      this.changeLoading = false
    },
    // 邀请人员
    inviteUser () {
      this.visibleYq = true
    },
    // 邀请人员(多次)
    inviteUserMany () {
      this.visibleYqMany = true
    },
    // 搜索
    onSearchInvite (value) {
      if ((/^(?:(?:\+|00)86)?1[3-9]\d{9}$/).test(this.inviteUserPhone)) {
        getUserByPhone(this.inviteUserPhone).then(res => {
          if (res.result.length>0&&res.success) {
            this.currentItem = res.result[0]
            this.hasInvitor = true
          } else {
            this.hasInvitor = false
            this.stepTwoShowContentYq = true
          }
        })
      } else {
        this.$message.error('手机号格式不正确')
      }
    },
    // 任务金额
    onChangeRwje () {
      if (this.rwje!==0) {
        this.inputYfje = (this.rwje * this.yfje).toFixed(2)
      }
    },
    // 单选预付金额
    onChangeYfje () {
      console.log('radio checked',this.yfje);
      if (this.yfje!==0) {
        this.inputYfje = (this.yfje * this.rwje).toFixed(2)
      }
    },
    // 发送短信
    sendDx () {
      if ((/^(?:(?:\+|00)86)?1[3-9]\d{9}$/).test(this.inviteUserPhone)) {
        sendInvitationSms(this.inviteUserPhone).then(res => {
          if (res.success) {
            this.$message.success(res.message)
            this.handleCancelYq()
          } else {
            this.$message.error(res.message)
          }
        })
      } else {
        this.$message.error('手机号格式不正确')
      }
    },
    // 确认发送
    handleOkYq () {
      if (this.submitDetail.payMode==='10') {
        if (this.rwje===0||this.rwje==='0') {
          this.$message.error('请输入任务金额')
          return
        }
        if (this.inputYfje&&this.inputYfje<0) {
          this.$message.error('请输入预付金额')
          return
        }
        if (Number(this.inputYfje)>Number(this.rwje)) {
          this.$message.error('预算金额不能大于任务金额')
          return
        }
        this.confirmLoadingYq = true
        invitationRecommendUser(Object.assign(this.currentItem,{taskId:this.taskId,advanceAmount:this.inputYfje,balanceAmount:this.wk})).then(res => {
          this.confirmLoadingYq = false
          if (res.success) {
            this.$message.success(res.message)
            this.handleCancelYq()
          } else {
            this.$message.error(res.message)
          }
        })
      } else {
        this.confirmLoadingYq = true
        invitationRecommendUser(Object.assign(this.currentItem,{taskId:this.taskId})).then(res => {
          this.confirmLoadingYq = false
          if (res.success) {
            this.$message.success(res.message)
            this.handleCancelYqMany()
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    // 取消邀请
    handleCancelYq () {
      this.visibleYq = false
      this.hasInvitor = false
      this.inviteUserPhone = ''
      this.rwje = 0
      this.inputYfje = 0
      this.yfje = 0
      this.stepTwoShowContentYq = false
    },
    // 取消邀请
    handleCancelYqMany () {
      this.visibleYqMany = false
      this.hasInvitor = false
      this.stepTwoShowContentYq = false
    },
    // 确认用工
    qryg(item) {
      this.currentItem = item
      checkRepetition({taskId:this.taskId,userId:item.userId}).then(res => {
        if (!res.success) {
          this.$message.warning('该用户已用工')
          this.inviteLists.forEach(list=>{
            if(list.userId===this.currentItem.userId) {
              list.hasInvite = true
            }
          })
          let ss = this.inviteLists
          this.inviteLists = []
          this.inviteLists = ss
        } else {
          this.visibleYg = true
        }
      })
    },
    // 确认用工多次
    qrygMany(item) {
      this.currentItem = item
      checkRepetition({taskId:this.taskId,userId:item.userId}).then(res => {
        if (!res.success) {
          this.$message.warning('该用户已用工')
          this.inviteLists.forEach(list=>{
            if(list.userId===this.currentItem.userId) {
              list.hasInvite = true
            }
          })
          let ss = this.inviteLists
          this.inviteLists = []
          this.inviteLists = ss
        } else {
          this.visibleYgMany = true
        }
      })
    },
    handleOkYg () {
      if (this.submitDetail.payMode==='10') {
        if (this.rwje===0||this.rwje==='0') {
          this.$message.error('请输入任务金额')
          return
        }
        if (this.inputYfje&&this.inputYfje<0) {
          this.$message.error('请输入预付金额')
          return
        }
        if (Number(this.inputYfje)>Number(this.rwje)) {
          this.$message.error('预算金额不能大于任务金额')
          return
        }
        this.confirmLoadingYg = true
        invitationRecommendUser(Object.assign(this.currentItem,{taskId:this.taskId,advanceAmount:this.inputYfje,balanceAmount:this.wk})).then(res => {
          this.confirmLoadingYg = false
          if (res.success) {
            this.$message.success(res.message)
            this.inviteLists.forEach(item=>{
              if(item.userId===this.currentItem.userId) {
                item.hasInvite = true
              }
            })
            this.handleCancelYg()
          } else {
            this.$message.error(res.message)
          }
        })
      } else {
        this.confirmLoadingYg = true
        invitationRecommendUser(Object.assign(this.currentItem,{taskId:this.taskId})).then(res => {
          this.confirmLoadingYg = false
          if (res.success) {
            this.$message.success(res.message)
            this.inviteLists.forEach(item=>{
              if(item.userId===this.currentItem.userId) {
                item.hasInvite = true
              }
            })
            this.handleCancelYgMany()
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    // 取消用工
    handleCancelYg () {
      this.visibleYg = false
      this.rwje = 0
      this.inputYfje = 0
      this.yfje = 0
    },
    // 取消用工多次
    handleCancelYgMany () {
      this.visibleYgMany = false
    },
    // 批量邀请
    inviteUsers () {
      this.uploadInfo = []
      this.visiblePl = true
    },
    // 汇总批量上传表格人数、金额
    importWorkerStatistics () {
      importWorkerStatistics(this.batchId).then(res => {
        if (res.success) {
          this.tableInfo = res.result
        }
      })
    },
    // 上传
    handleChange(info) {
      if (info.fileList.length>1) {
        info.fileList.shift()
      }
      if (info.file.status==='removed'&&info.fileList.length===0) {
        this.batchId = ''
        this.$refs.raskTable.refresh(true)
        this.tableInfo = {
          taskAmount: 0.00,
          advanceAmount:0.00,
          balanceAmount:0.00,
          feeAmount:0.00,
          payAmount:0.00,
          countNum:0.00,
        }
      }
      // console.log(info,info.file, info.fileList);
      this.uploadInfo = info.fileList
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status==='done') {
        if (info.file.response.success) {
          this.batchId = info.file.response.result.batchId
          this.$message.success(info.file.response.message);
          this.$refs.raskTable.refresh(true)
          this.importWorkerStatistics()
        } else {
          this.$message.error(info.file.response.message);
        }
      }
    },
    handleOkPl () {
      let params = {
        taskId:this.taskId,
        batchId: this.batchId
      }
      this.confirmLoadingPl = true
      submitApply(params).then(res=>{
        this.confirmLoadingPl = false
        if (res.success) {
          this.$message.success(res.message)
          this.handleCancelPl()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleCancelPl () {
      this.visiblePl = false
      this.batchId = ''
      this.$refs.raskTable.refresh(true)
      this.tableInfo = {
        taskAmount: 0.00,
        advanceAmount:0.00,
        balanceAmount:0.00,
        feeAmount:0.00,
        payAmount:0.00,
        countNum:0.00,
      }
    },
    // 发送短信
    sendMsg (record) {
      sendInvitationSms(record.phoneNo).then(res => {
        if (res.success) {
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 第三步
    contiuneCreate () {
      this.step = 0
    },
    // 详情
    toDetail () {
      window.sessionStorage.setItem('raskId',this.taskId)
      if (this.submitDetail.payMode==='10') {
        window.localStorage.setItem('submenu','raskDetail')
        this.$router.push('/raskDetail')
      } else {
        window.localStorage.setItem('submenu','raskDetailMany')
        this.$router.push('/raskDetailMany')
      }
    }
  },
}
</script>
<style scoped lang="less">
.title{
  font-size: 24px;
  color: #202020;
  margin: 0 0 20px 30px;
}
.step{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .left{
    width: 1000px;
  }
  .right{
    width: 500px;
    .ant-form{
      .ant-form-item{
        display: flex;
      }
    }
  }
}
.step-02{
  width: 100%;
  padding: 40px 44px 40px 27px;
  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 35px;
    span{
      margin-left: 17px;
      font-size: 22px;
      color: #000000;
    }
  }
  .content{
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    li{
      margin: 17px 0 0 17px;
      width: 450px;
      background: #FFFFFF;
      border: 1px solid #E1E4E8;
      border-radius: 3px;
      .construction{
        padding: 16px;
        border-bottom: 1px solid #E1E4E8;
        display: flex;
        .c-left{
          width: 64px;
          height: 64px;
          text-align: center;
          line-height: 64px;
          font-size: 26px;
          background: #DDDDDD;
          border-radius: 50%;
          margin-right: 13px;
        }
        .c-right{
          width: 300px;
          .cr-title{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .name{
              font-size: 20px;
              color: rgba(0, 0, 0, 0.85);
              margin-right: 8px;
            }
            .phone{
              font-size: 14px;
              color: rgba(0, 0, 0, 0.65);
            }
            .hzcs{
              color: #2878FF;
              font-size: 14px;
            }
          }
          .desc{
            width: 100%;
            text-align: justify;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);
            margin: 5px 0 10px 0;
            height: 42px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
      .footer{
        width:100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 16px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.85);
        span{
          margin-left: 8px;
          padding: 3px 10px;
          background: #EBF0F8;
          border-radius: 10px;
          text-align: center;
          line-height: 20px;
        }
      }
    }
  }
  .stept-opt{
    margin: 20px 0 0 17px;
    width: 100%;
    text-align: center;
  }
}
.step-03{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 70px 0 359px 0;
  .step3-head{
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  margin: 30px 0 19px 0;
  }
  .step3-desc{
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    margin: 0 0 29px 0;
  }
}
.fsyq-head{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .fsyq-title{
    font-size: 14px;
    color: rgba(32, 32, 32, 0.65);
    margin: 0 0 14px 0;
  }
  .user-info{
    margin: 24px 0;
    .img{
      width: 100px;
      height: 100px;
      border-radius: 3px;
      margin-bottom: 8px;
      text-align: center;
      line-height: 100px;
      font-size: 40px;
      background: #DDDDDD;
      border-radius: 50%;
    }
    div{
      text-align: center;
      color: rgba(32, 32, 32, 0.65);
      font-size: 14px;
    }
  }
}
.common-content{
  .cc-title{
    padding: 18px 0;
    border-bottom: 1px solid #F0F0F0;
    font-size: 18px;
    color: #000000;
  }
  .cc-desc{
    font-size: 14px;
    color: rgba(32, 32, 32, 0.65);
    margin: 24px 0 0 0;
  }
  .cc-content{
    display: flex;
    align-items: center;
    align-items: center;
    font-size: 16px;
    color: #333333;
    margin:24px 0 0 0;
  }
  .cc-footer{
    margin: 24px 0;
    padding: 20px 80px;
    width: 640px;
    // height: 95px;
    background: #FAFAFA;
    border-radius: 3px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    .ccf-left,.ccf-right{
      color: #333333;
      font-size: 16px;
      span{
        font-size: 24px;
        color: #FF4D4F;
      }
      div{
        &:last-child{
          margin: 10px 0 0 0;
          font-size: 14px;
          color: rgba(32, 32, 32, 0.65);
        }
      }
    }
    .ccf-right{
      margin-left: 100px;
    }
  }
}
.common-opt{
  text-align: center;
  margin: 20px 0 20px 0;
}
.no-invite{
  margin: 17px 0 77px 0;
  text-align: center;
  font-size: 14px;
  color: rgba(32, 32, 32, 0.65);
}
.pl-table{
  border: 1px solid #DADADA;
  border-radius: 6px;
  margin: 15px 0 0 0;
}
.pl-total{
  margin: 10px 0 30px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .total-ze{
    color: #33383E;
    font-size: 16px;
    margin: 15px 15px 0 0;
    .total-zee{
      color: #FF4D4F;
      font-size: 24px;
    }
    .total-zeee{
      color: #FF4D4F;
      font-size: 16px;
    }
  }
}
.xzmb{
  position: absolute;
  top: 5px;
  left: 100px;
}
</style>
